import axios from 'axios';
const config = require('./config.json');

// MODULE DE CONNEXION

export const login = (name, code) => {

    return new Promise((resolve, reject) => {

        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/login", { name, code }).then((response) => {
            if (response.status === 200) resolve(response.data);
            else reject(response.data.message);
        }).catch((error) => {
            reject(error.response.data.message);
        });
    });

}

export const userinfo = () => {

    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/userinfo", { token: localStorage.getItem("token") }).then((response) => {
            if (response.status === 200) resolve(response.data);
            else reject(response.data.message);
        }).catch((error) => {
            reject(error.response.data.message);
        });
    });

}


// RÉCUPÉRATION DES INFORMATIONS DE LA COURSE ACTUELLE

export const getCurrentStatus = () => {

    return new Promise((resolve, reject) => {

        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/status", { token: localStorage.getItem("token") }).then((response) => {
            if (response.status === 200) resolve(response.data);
            else reject(response.data.message);
        }).catch((error) => {
            reject(error.response.data.message);
        });
    });

}

// MODULE D'ADMINISTRATION

export const getAllGroups = () => {

    return new Promise((resolve, reject) => {

        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/groups/@all", { token: localStorage.getItem("token") }).then((response) => {
            if (response.status === 200) resolve(response.data);
            else reject(response.data.message);
        }).catch((error) => {
            reject(error.response.data.message);
        });
    });

}


export const getStocks = () => {

    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/stocks/get", { token: localStorage.getItem("token") }).then((response) => {
            if (response.status === 200) resolve(response.data);
            else reject(response.data.message);
        }).catch((error) => {
            reject(error.response.data.message);
        });
    });

}

// RÉCUPÉRATION DES GROUPES DU REFERENT

export const getMyGroups = (timeslot) => {

    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/groups", { token: localStorage.getItem("token"), timeSlot: timeslot }).then((response) => {
            if (response.status === 200) resolve(response.data);
            else reject(response.data.message);
        }).catch((error) => {
            reject(error.response.data.message);
        });

    });
}

// Report

export const reportIncident = (stand, content, timeslot) => {
    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/report/" + timeslot, { token: localStorage.getItem("token"), stand, content }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}


// ADMIN

export const startRace = () => {
    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/start", { token: localStorage.getItem("token") }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}

export const stopRace = () => {
    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/stop", { token: localStorage.getItem("token") }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}

// 

export const getPlayerLeaderboard = () => {
    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/leaderboard/@players", { token: localStorage.getItem("token") }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}

export const playSound = (sound) => {
    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/playsound", { sound, token: localStorage.getItem("token") }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });
}

export const editConfig = (timeslot, published_scoreboard) => {

    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/config/edit", { timeslot, published_scoreboard, token: localStorage.getItem("token") }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}


export const updateStock = (itemId, quantity) => { 
    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/stocks/update/" + itemId, { token: localStorage.getItem("token"), quantity: quantity }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}

export const getCredentials = () => {

    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/credentials", { token: localStorage.getItem("token") }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}

export const deliverStock = (standId, timeslot, amount) => {

    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/stocks/deliver/" + standId + "/" + timeslot, { token: localStorage.getItem("token"), amount }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}

export const createSSRMAcount = (username, rank) => {

    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/account/create", { token: localStorage.getItem("token"), username, rank }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}

export const editPlayerPayed = (studentId, payed) => {

    return new Promise((resolve, reject) => {
        axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/student/" + studentId + "/payed", { token: localStorage.getItem("token"), payed }).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });

    });

}

