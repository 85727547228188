
import { Component, createRef } from "react";
import { Navigation } from "../components/Navigation";
import config from "../components/config.json";

// react-hot-toast
import toast from 'react-hot-toast';
import axios from "axios";

import { getMyGroups, reportIncident, userinfo } from "../components/api";
import { NavLink } from "react-router-dom";
import Loader from "../components/Loader";

class Home extends Component {

    constructor(props) {

        super(props);

        this.state = {
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            sound: "",
            groups: [],
            students: [],
            savedStudents: [],
            reports: [],
            rank: 'referent',
            tab: 'general',
            adminGroups: [],
            timeSlot: 0,
            end: 0,
            isLoading: true
        }

        this.searchRef = createRef();

        this.reportStandRef = createRef();
        this.reportIncidentRef = createRef();

        this.standSectionRef = createRef();


    }
    componentDidMount() {

        window.scrollTo(0, 0);
        this.setState({ timeSlot: this.props.timeSlot });
        userinfo().then((response) => {
            this.setState({ rank: response.rank });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location.href = "./login";;
        });



        getMyGroups(this.props.timeSlot).then((response) => {
            let res = response.groups;
            if (!Array.isArray(res)) res = [res];
            this.setState({ students: res, savedStudents: res });
            let groups = [];
            res.forEach((student) => {

                if (!groups.includes(student.groupLink)) groups.push(student.groupLink);

            });

            this.setState({ groups: groups });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
        });





        this.socket = new WebSocket(config.api.PRODUCTION_GATEWAY_URL);
        this.socket.onopen = () => {
            if (this.socket.readyState === WebSocket.OPEN) {
                toast.dismiss();
                toast.success("Connexion établie avec le serveur.");
                this.setState({ isLoading: false });
            }

        }


        this.socket.onclose = () => {

            if (!this.socket.readyState) {

                if (this.socket.readyState === WebSocket.CLOSED)
                    toast.error("La connexion avec le serveur a été fermée.");
                else if (this.socket.readyState === WebSocket.CLOSING)
                    toast.error("La connexion avec le serveur est en cours de fermeture.");
                else if (this.socket.readyState === WebSocket.CONNECTING)
                    toast.loading("La connexion avec le serveur est en cours d'établissement.");
                else
                    toast.error("La connexion avec le serveur a été perdue.");


            }

        }


        this.socket.onmessage = (message) => {

            let messageinfos = JSON.parse(message.data);

            if (messageinfos.event === "HEARTBEAT") {
                this.socket.send(JSON.stringify({
                    "command": "heartbeat"
                }));
            }

            // PLAYER_EDITED
            if (messageinfos.event === "PLAYER_EDITED") {
                if (this.state.students.find((student) => parseInt(student.id) === parseInt(messageinfos.playerId))) {
                    this.setState({
                        students: this.state.students.map((student) => {
                            if (parseInt(student.id) === parseInt(messageinfos.playerId)) {
                                student.promise = messageinfos.promise;
                                student.tours = messageinfos.newTour;
                            }
                            return student;
                        })
                    });

                    document.getElementById(messageinfos.playerId + "P").value = messageinfos.promise;
                    document.getElementById(messageinfos.playerId + "T").value = messageinfos.newTour;

                }

            }

        }

    }

    componentDidUpdate() {
        if (this.props.timeSlot !== this.state.timeSlot) {


            getMyGroups(this.props.timeSlot).then((response) => {
                let res = response.groups;
                if (!Array.isArray(res)) res = [res];
                this.setState({ students: res, savedStudents: res });
                let groups = [];
                res.forEach((student) => {

                    if (!groups.includes(student.groupLink)) groups.push(student.groupLink);

                });

                this.setState({ groups: groups });
            }).catch((error) => {
                toast.error("Erreur lors de la connexion !");
            });

            this.setState({ timeSlot: this.props.timeSlot });


        }
    }

    componentWillUnmount() {
        this.socket.close();
        toast.dismiss();
    }

    render() {

        const handleReportIncident = () => {

            this.setState({ isLoading: true });
            reportIncident(this.reportIncidentRef.current.value, this.reportStandRef.current.value, this.props.timeSlot).then((response) => {
                toast.success("L'incident a été signalé.");
                this.setState({ isLoading: false });
            }).catch((error) => {
                toast.error("Erreur lors de la connexion !");
                this.setState({ isLoading: false });
            });
        }

        const handleSearchStudent = () => {

            if (!this.searchRef.current.value) this.setState({ students: this.state.savedStudents });
            this.setState({ students: this.state.savedStudents.filter((student) => student.name.toLowerCase().includes(this.searchRef.current.value.toLowerCase()) || student.firstname.toLowerCase().includes(this.searchRef.current.value.toLowerCase()) || student.id.toString().includes(this.searchRef.current.value)) });

        }


        const handleEditStudent = (id) => {

            let promise = this.state.students.find((student) => student.id === id).promise;
            let newTour = this.state.students.find((student) => student.id === id).tours;

            this.setState({ isLoading: true });


            toast.promise(new Promise((resolve, reject) => {

                axios.post(config.api.PRODUCTION_API_URL + "/apps/race24/edit/" + id, {

                    promise: promise,
                    newTour: newTour,
                    token: localStorage.getItem("token")

                }).then((response) => {
                    this.setState({ isLoading: false });
                    resolve(response.data);
                }).catch((error) => {
                    this.setState({ isLoading: false });
                    reject(error);
                });

            }), {
                loading: 'Mise à jour de l\'élève...',
                success: 'L\'élève a été mis à jour !',
                error: 'Impossible de mettre à jour l\'élève.',
            });

        }



        return <>

            {this.state.isLoading ? <Loader /> : null}

            <div className="container mt-5">
                <div className="row">

                    {this.props.raceStatus === 2 ?
                        <div className="card card-end mb-3">

                            <div className="card-body">

                                <h5 className="mb-3">Course terminée : informations pratiques</h5>

                                Félicitations ! Vous avez couru pendant 45 minutes. Le <NavLink to="/scoreboard">classement</NavLink> sera annoncé lors de la cérémonie du 24 mai 2024.
                                Afin d'assurer un classement juste, merci de bien vouloir renseigner les tours de leurs élèves ainsi que d'afficher le bon nombre de parrainages, une partie du classement de votre classe en dépend.<br /><br />

                                <b>Informations pratiques</b><br />
                                <ul>
                                    <li>Vous pouvez également vous rendre au stand d'accueil pour signaler des détritus  sur le parcours.</li>
                                    <li>Les élèves peuvent se rendre aux toilettes, mais doivent être de retour sous 5 minutes.</li>
                                    <li>Les élèves sont invités à préparer leurs affaires pour le retour en attendant nos instructions.</li>
                                </ul>




                            </div>
                        </div> : null}


                    <div className="col-xs-12 col-md-6 col-xl-4 col-xxl-3 mb-3">

                        <div className={this.props.raceStatus === 0 ? "race_info mb-3" : this.props.raceStatus === 1 ? "race_info border-success mb-3" : "race_info border-danger mb-3"}>
                            <span className="title"><i className={this.props.raceStatus === 0 ? "fa-solid fa-flag me-2 text-secondary" : this.props.raceStatus === 1 ? "fa-solid fa-flag me-2 text-success" : "fa-solid fa-flag me-2 text-danger"}></i> Course #1</span>
                            <span className="text">{
                                this.props.raceStatus === 0 ? "En attente de lancement par l'hôte distant." : this.props.raceStatus === 1 ? "La course est lancée." : "La course a été arrêtée par l'hôte distant."
                            }</span>

                            <span className="date">{this.props.raceStatus === 0 ? "Prévue à " + new Date(this.props.timeSlot * 1000).getHours() + "h" + (new Date(this.props.timeSlot * 1000).getMinutes() < 10 ? "0" + new Date(this.props.timeSlot * 1000).getMinutes() : new Date(this.props.timeSlot * 1000).getMinutes()) : this.props.raceStatus === 1 ? "Fin estimée à " + new Date(this.props.end * 1000).getHours() + "h" + (new Date(this.props.end * 1000).getMinutes() < 10 ? "0" + new Date(this.props.end * 1000).getMinutes() : new Date(this.props.end * 1000).getMinutes()) : "Arrêtée à " + new Date(this.props.end * 1000).getHours() + "h" + (new Date(this.props.end * 1000).getMinutes() < 10 ? "0" + new Date(this.props.end * 1000).getMinutes() : new Date(this.props.end * 1000).getMinutes())}</span>
                        </div>

                        <label>Actions</label>

                        <div className="d-grid gap-2">
                            <button className="btn-main w-100 justify-content-center" type="button" data-bs-toggle="modal" data-bs-target="#reportIncidentModal">Signaler un incident</button>

                            <button className="btn-outline-main w-100 justify-content-center" type="button" onClick={() => {
                                this.componentDidMount();
                            }}>Se reconnecter</button>
                        </div>

                    </div>

                    <div className="col-xs-12 col-md-6 col-xl-8 col-xxl-9">
                        <h3 className="mb-3">Fiche de groupe : {
                            this.state.groups.map((group) => {
                                if (this.state.groups.indexOf(group) !== this.state.groups.length - 1) return group + ", ";
                                else return group

                            })
                        } ({this.state.students ? this.state.students.length : 0} élève{this.state.students ? this.state.students.length >= 2 ? "s" : "" : null})</h3>

                        {!this.state.savedStudents || this.state.savedStudents.length === 0 ? <div className="alert alert-danger">
                            <p>Vous n'avez aucun élève inscrit sur le créneau en cours.<br />Cela se produit généralement lorsque vous venez de terminer une course ou que vous êtes en avance.
                                Si vous pensez qu'il s'agit d'une erreur, dirigez vous vers le stand de l'organisation.<br /><br />

                                Statut: <b>Le créneau spécifié par l'hôte distant ne contient aucun de vos élèves</b>.

                                <hr />

                                <b>Le lendemain de la course.</b><br />
                                Vous pourrez voir les résultats de la course et de vos élèves sur cette page.


                            </p>

                        </div> :
                            <>
                                <div className="search mb-3">
                                    <i className="fa-solid fa-search"></i>
                                    <input type="text" placeholder="Rechercher un coureur par nom, prénom ou N° associé" className="form-control" onChange={() => handleSearchStudent()} ref={this.searchRef} />
                                </div>

                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-secondary">NOM Prénom (#)</th>
                                                <th scope="col" className="text-secondary">Promesse</th>
                                                <th scope="col" className="text-secondary">Tours</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.students.map((student) => {
                                                    return <tr id={student.id} key={student.id}>
                                                        <td className={student.disp && student.disp === 1 ? "text-danger" : null
                                                        }>{student.name} {student.firstname} ({student.id})</td>
                                                        <td>
                                                            {student.disp && student.disp === 1 ?
                                                                <b title="Comité d'organisation sur cette course" style={{ cursor: "help" }}><i className="fa-solid fa-exclamation-triangle me-2 text-danger"></i> C.O.</b> :
                                                                <>


                                                                    <input

                                                                        type="number"
                                                                        className="form-integer"
                                                                        defaultValue={this.state.students.find((students) => students.id === student.id).promise}

                                                                        onFocus={() => {
                                                                            // On attend 5 secondes puis on blur
                                                                            setTimeout(() => {
                                                                                document.getElementById(student.id + "P").blur();
                                                                            }, 5000);
                                                                        }}

                                                                        onBlur={() => {
                                                                            if (parseInt(document.getElementById(student.id + "P").value) === this.state.students.find((students) => students.id === student.id).promise) return;

                                                                            // Modifier la valeur de la promesse dans le state (sans handleEditStudent)

                                                                            this.setState({

                                                                                students: this.state.students.map((students) => {
                                                                                    if (students.id === student.id) {
                                                                                        students.promise = parseInt(document.getElementById(student.id).getElementsByTagName("input")[0].value);
                                                                                    }
                                                                                    return students;
                                                                                }
                                                                                )

                                                                            })

                                                                            handleEditStudent(student.id)
                                                                        }

                                                                        }
                                                                        id={student.id + "P"}

                                                                    />€</>

                                                            }

                                                        </td>
                                                        <td>
                                                            {this.props.raceStatus !== 0 ? <>

                                                                {student.disp && student.disp === 1 ? <b title="Comité d'organisation sur cette course" style={{ cursor: "help" }}><i className="fa-solid fa-exclamation-triangle me-2 text-danger"></i> C.O.</b> : <>
                                                                    <i className="fa-solid fa-minus-circle me-2 text-primary hoverCursorClick" onClick={() => {

                                                                        if (this.state.students.find((students) => students.id === student.id).tours - 1 < 0) return;

                                                                        this.setState({
                                                                            students: this.state.students.map((students) => {
                                                                                if (students.id === student.id) {
                                                                                    if (students.tours - 1 < 0) return students.tours = 0;
                                                                                    students.tours = students.tours - 1;
                                                                                }
                                                                                return students;
                                                                            })
                                                                        })

                                                                        document.getElementById(student.id + "T").value = this.state.students.find((students) => students.id === student.id).tours;

                                                                        handleEditStudent(student.id);

                                                                    }}></i>

                                                                    <input type="number" className="form-integer" defaultValue={this.state.students.find((students) => students.id === student.id).tours}

                                                                        onChange={(e) => {

                                                                            if (e.target.value < 0) return e.target.value = 0;


                                                                            this.setState({
                                                                                students: this.state.students.map((students) => {
                                                                                    if (students.id === student.id) {
                                                                                        students.tours = parseInt(e.target.value);
                                                                                    }
                                                                                    return students;
                                                                                })
                                                                            })

                                                                            handleEditStudent(student.id);

                                                                        }
                                                                        }

                                                                        id={student.id + "T"}

                                                                    />

                                                                    <i className="fa-solid fa-plus-circle ms-2 text-primary hoverCursorClick" onClick={() => {

                                                                        this.setState({
                                                                            students: this.state.students.map((students) => {
                                                                                if (students.id === student.id) {
                                                                                    students.tours = students.tours + 1;
                                                                                }
                                                                                return students;
                                                                            })
                                                                        })

                                                                        // Actualise la valeur du input

                                                                        document.getElementById(student.id + "T").value = this.state.students.find((students) => students.id === student.id).tours;



                                                                        handleEditStudent(student.id);
                                                                    }}></i>
                                                                </>}
                                                            </> : <b title="La course n'est pas lancée" style={{ cursor: "help" }}><i className="fa-solid fa-exclamation-triangle me-2 text-warning"></i> N/L</b>}


                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>}

                    </div>
                </div>
            </div >
            <div className="modal modal-lg fade" id="reportIncidentModal" tabindex="-1" aria-labelledby="reportIncidentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="reportIncidentModalLabel">Signaler un incident</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <label className="fr label">Votre N° de stand</label>
                            <label className="fr sublabel">Format attendu : 1 (si vous n'êtes sur aucun stand, entrez 7)</label>
                            <input className="fr input" type="text" ref={this.reportIncidentRef} />


                            <label className="fr label">Type d'incident</label>
                            <input className="fr input" type="text" ref={this.reportStandRef} autoComplete="off" list="datalistOptions" />
                            <datalist id="datalistOptions">
                                <option value="Malaise coureur" />
                                <option value="Besoin de collation (eau, pomme, ...)" />
                                <option value="Incident technique (stand, ...)" />
                                <option value="Autre" />
                            </datalist>


                            <button type="button" className="btn-main mb-3 me-3" data-bs-dismiss="modal"
                                onClick={() => handleReportIncident()}>Signaler</button>
                            <button type="button" className="btn-outline-main" data-bs-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    }
}

export default Home;
