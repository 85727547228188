
import { Component, createRef } from "react";

// react-hot-toast
import toast from 'react-hot-toast';

import { userinfo } from "../components/api";
import { Link, NavLink } from "react-router-dom";

class Hub extends Component {

    constructor(props) {

        super(props);

        this.state = {
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            sound: "",
            groups: [],
            students: [],
            savedStudents: [],
            reports: [],
            rank: 'referent',
            tab: 'general',
            adminGroups: [],
            timeSlot: 0,
            end: 0
        }

        this.audioRef = createRef();
        this.fstRef = createRef();
        this.alertRef = createRef();
        this.soundRef = createRef();
        this.soundAnnounceRef = createRef();
        this.searchRef = createRef();

        this.reportStandRef = createRef();
        this.reportIncidentRef = createRef();

        this.standSectionRef = createRef();


    }
    componentDidMount() {

        window.scrollTo(0, 0);
        userinfo().then((response) => {
            this.setState({ rank: response.rank });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location.href = "./login";
        });

    }
    render() {

        return <>


            <div className="container mt-5">
                <div className="row">

                    <div className="col-xs-12 col-md-6 col-lg-4">

                        <NavLink to="/referent">
                            <div className="card card-tile mb-4">

                                <div className="card-body p-4">

                                    <span className="tile-icon">
                                        <i className="fa-solid fa-person-running"></i>
                                    </span>

                                    <h3 className="tile-title">Professeurs et Référents BIS</h3>

                                    <span className="tile-subtitle">Décompte des tours et des parrainages des élèves</span>

                                    <div className="tile-action">
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                    </div>


                    <div className="col-xs-12 col-md-6 col-lg-4">

                        <NavLink to="/stocks">
                            <div className="card card-tile mb-4">

                                <div className="card-body p-4">

                                    <span className="tile-icon">
                                        <i className="fa-solid fa-boxes-packing"></i>
                                    </span>

                                    <h3 className="tile-title">Stocks</h3>

                                    <span className="tile-subtitle">Suivi des inventaires, des ressources et des collations de la course</span>

                                    <div className="tile-action">
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                    </div>

                    <div className="col-xs-12 col-md-6 col-lg-4">

                        <NavLink to="/dashboard">
                            <div className="card card-tile mb-4">

                                <div className="card-body p-4">

                                    <span className="tile-icon">
                                        <i className="fas fa-user"></i>
                                    </span>

                                    <h3 className="tile-title">Organisation</h3>

                                    <span className="tile-subtitle">Gestion globale de la course et des équipes de bénévoles</span>

                                    <div className="tile-action">
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-4">

                        <NavLink to="/documents">
                            <div className="card card-tile mb-4">

                                <div className="card-body p-4">

                                    <span className="tile-icon">
                                        <i className="fas fa-file-alt"></i>
                                    </span>

                                    <h3 className="tile-title">Documents</h3>

                                    <span className="tile-subtitle">Télécharger les documents administratifs relatifs à la course</span>

                                    <div className="tile-action">
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-4">

                        <NavLink to="/payments">
                            <div className="card card-tile mb-4">

                                <div className="card-body p-4">

                                    <span className="tile-icon">
                                        <i className="fas fa-euro-sign"></i>
                                    </span>

                                    <h3 className="tile-title">Paiements</h3>

                                    <span className="tile-subtitle">Suivi du paiement des dons promis par les parrains des élèves</span>

                                    <div className="tile-action">
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                    </div>

                    {/*<div className="col-xs-12 col-md-6 col-lg-4">

                        <Link to="https://www.youtube.com/playlist?list=PLTlofb292NpLBW1ytzBeD_2mq64nXx0OH&si=nO7YXIqidKN9mwEw" target="_blank" rel="noreferrer">
                            <div className="card card-tile mb-4">

                                <div className="card-body p-4">

                                    <span className="tile-icon">
                                        <i className="fas fa-play"></i>
                                    </span>

                                    <h3 className="tile-title">Playlist</h3>

                                    <span className="tile-subtitle">Accéder à la playlist de diffusion de la course sur YouTube</span>

                                    <div className="tile-action">
                                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>*/}

                    <div className="col-xs-12 col-md-6 col-lg-4">

                        <NavLink to="/settings">
                            <div className="card card-tile mb-4">

                                <div className="card-body p-4">

                                    <span className="tile-icon">
                                        <i className="fas fa-cogs"></i>
                                    </span>

                                    <h3 className="tile-title">Paramètres</h3>

                                    <span className="tile-subtitle">Modifier les créneaux de courses et les accès à la plateforme</span>

                                    <div className="tile-action">
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                    </div>



                </div>
                <div className="text-center text-muted my-4">2024 &copy; Plateforme développée par Ewen Rodrigues de Oliveira<br className="d-none d-md-block" /> Tous droits réservés - <a href="https://studios.naxalian.fr" target="_blank" rel="noreferrer">Naxalian.Fr</a></div>
            </div>

        </>
    }

}

export default Hub;