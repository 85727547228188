import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {


    render() {

        return (
            <>
                <nav className="navbar navbar-expand-lg bg-white" >
                    <div className="container">
                        <a className="navbar-brand" href="https://www.lyc-polyvalent-monod-enghien.fr/" target="_blank">

                            {this.props.raceStatus === 1 ? <>
                                <div className="spinner-grow text-success me-3 d-flex d-lg-none" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>

                            </>
                                : this.props.raceStatus === 2 ? <>
                                    <div className="spinner-grow text-danger me-3 d-flex d-lg-none" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </>
                                    : <img src={process.env.PUBLIC_URL + "/rf.png"} alt="logo" className="brand-img d-flex d-lg-none" />
                            }
                            <img src={process.env.PUBLIC_URL + "/rf.png"} alt="logo" className="brand-img d-none d-lg-flex" />

                            <div className="school-detail">
                                <span className="name">Lycée Gustave Monod</span><br />
                                <span className="city">Enghien-les-Bains</span>
                            </div>

                            {this.props.raceStatus === 1 ? <>
                                <div className="spinner-grow text-success me-3 d-none d-lg-flex" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>

                            </>
                                : this.props.raceStatus === 2 ? <>
                                    <div className="spinner-grow text-danger me-3 d-none d-lg-flex" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </>
                                    : null}


                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            </ul>
                            <div className="d-flex flex-row align-items-center">
                                {!this.props.raceStatus || this.props.raceStatus === 0 ? <b title="La course n'est pas lancée" style={{ cursor: "help" }} className="d-none d-lg-flex me-4"><i className="fa-solid fa-exclamation-triangle me-2 text-warning"></i> N/L</b> : null}
                                <button className="btn-outline-main me-2" data-bs-toggle="modal" data-bs-target="#logoutModal"><i className="fa-solid fa-power-off"></i></button>
                                <NavLink className="btn-main" to="/">{this.props.name ? <>
                                    <i className="fa-regular fa-folder-closed me-3 fa-xs"></i> Modules
                                </> : "Connexion"}</NavLink>
                            </div>
                        </div>
                    </div>
                </nav >

                <div className="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Êtes vous sûr de vouloir vous déconnecter ?<br/>
                                <small>Vous pourrez vous reconnecter à tout moment</small><br/><br/>

                                
                                <button type="button" className="btn-main me-3 mb-3" onClick={() => {
                                    localStorage.removeItem("name");
                                    localStorage.removeItem("token");
                                    window.location.reload();
                                }}>Déconnexion</button> <button type="button" className="btn-outline-main" data-bs-dismiss="modal">Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    }

}

export { Navigation };