import React from "react";
import { Backdrop } from "@mui/material";
import { Typography } from "@mui/material";


class PPMS extends React.Component {
    render() {
        return (
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9999, display: 'flex', flexDirection: 'column', textAlign: 'center',
                // Rendre + foncé le fond du Backdrop
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
            }} open={true}>
                <Typography variant="h4" component="div" gutterBottom>
                    <i className="fas fa-exclamation-triangle text-danger me-3"></i>
                    Alerte PPMS
                </Typography>

                <Typography variant="body1" component="div" gutterBottom>
                    Le chef d'établissement et/ou l'organisateur de la course vous demande d'appliquer les dispositions du plan particulier de mise en sûreté (PPMS).<br />
                    Un incident est en cours. Le signal sonore retentira dans quelques instants. Veuillez vous préparer à l'appliquer.<br /><br />
                    <a href="" onClick={() => { window.location.reload(); }} style={{
                        
                        color: '#fff',
                        paddingBottom: '5px',
                        fontWeight: 'bold',
                        borderBottom: '2px dashed #fff',
                    }}>Quitter la fenêtre</a>
                </Typography>
            </Backdrop>

        );
    }
}

export default PPMS;
