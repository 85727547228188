
import { Component, createRef } from "react";
import { useNavigate } from "react-router-dom";

// react-hot-toast
import toast from 'react-hot-toast';

// Api
import { login } from "../components/api";
import Loader from "../components/Loader";

//import { preRegister } from "../components/api";
class LoginExport extends Component {

    constructor(props) {

        super(props);

        this.state = {
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            sound: "",
            isLoading: false,
        }

        this.identifiantRef = createRef();
        this.codeRef = createRef();



    }

    componentDidMount() {

        if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
            return this.props.navigate("/login");
        }

    }

    render() {

        const handleLogin = () => {

            this.setState({ isLoading: true })

            if (!this.identifiantRef.current.value) return toast.error("Veuillez choisir un identifiant !");
            if (!this.codeRef.current.value) return toast.error("Veuillez entrer un code !");

            login(this.identifiantRef.current.value, this.codeRef.current.value).then((response) => {
                toast.success("Connexion réussie !");
                this.setState({ isLoading: false });
                localStorage.setItem("token", response.token);
                localStorage.setItem("name", (this.identifiantRef.current.value).toUpperCase());
                window.location.href = "./";
            }).catch((error) => {
                this.codeRef.current.value = "";
                this.identifiantRef.current.focus();
                this.setState({ isLoading: false });
                return toast.error(error);
            });

        }

        return <>
            {this.state.isLoading ? <Loader /> : null}
            <div className="container mt-5">
                <div className="row">


                    <div className="card mb-3">

                        <div className="card-body p-4">

                            <span className="text-center d-block mb-3">Une connexion est requise pour accéder à l'espace de la course</span>

                            <label className="fr label">Identifiant de connexion</label>
                            <label className="fr sublabel">L'identifiant est fourni par l'organisateur de la course</label>
                            <input className="fr input" type="text" ref={this.identifiantRef} />


                            <label className="fr label">Code d'authentification</label>
                            <input className="fr input" type="password" ref={this.codeRef} autoComplete="off" />

                            <button className="fr btn-main me-3 mb-3" type="button" onClick={() => handleLogin()}>Se connecter</button>
                            <button className="fr btn-outline-main" type="button" data-bs-toggle="modal" data-bs-target="#helpModal">Besoin d'aide ?</button>

                        </div>

                    </div>

                </div>
            </div>
            <div className="modal fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Les identifiants de connexion à la plateforme sont fournis par l'organisateur de la course. Si vous n'avez pas reçu vos identifiants ou que vous avez des difficultés à vous connecter, veuillez vous rendre au stand de l'organisateur de la course.
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default function Login() {

    const navigate = useNavigate();

    return <LoginExport navigate={navigate} />

}