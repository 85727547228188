import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

class Loader extends React.Component {
    render() {
        return (
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9999 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}

export default Loader;
