
import { Component, createRef } from "react";

// react-hot-toast
import toast from 'react-hot-toast';

// Api

import { editPlayerPayed, getAllGroups, getPlayerLeaderboard, playSound, reportIncident, startRace, stopRace, userinfo } from "../components/api";
import Loader from "../components/Loader";

//import { preRegister } from "../components/api";
class Payments extends Component {

    constructor(props) {

        super(props);

        this.state = {
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            sound: "",
            groups: [],
            students: [],
            savedStudents: [],
            rank: 'referent',
            tab: 'stands',
            adminGroups: [],
            end: 0,
            timeslot: 0,
            isLoading: true,
            student: [],
        }

        this.searchRef = createRef();
        this.payedRef = createRef();


    }

    componentDidMount() {

        window.scrollTo(0, 0);
        userinfo().then((response) => {
            if (response.rank !== "sysadmin" && response.rank !== "arbitre" && response.rank !== "surveillant") return window.location.href = "/";
            this.setState({ rank: response.rank })
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
            window.location.href = "./login";;
        });

        getAllGroups().then((response) => {
            let res = response.groups;
            if (!Array.isArray(res)) res = [res];

            // Trier res.stands.students par name ASC
            res.forEach((timeslotInfo) => {
                timeslotInfo.stands.forEach((standInfo) => {
                    standInfo.students.sort((a, b) => a.name.localeCompare(b.name));
                });
            });

            this.setState({ adminGroups: res, isLoading: false });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
        });




    }
    render() {

        const searchPlayer = () => {

            this.setState({ isLoading: true, student: [] });

            let search = this.searchRef.current.value;

            if (!search) return this.setState({ isLoading: false });
            search = search.toLowerCase();
            // rechercher dans this.state.adminGroups

            let student = [];

            this.state.adminGroups.forEach((timeslotInfo) => {
                timeslotInfo.stands.forEach((standInfo) => {
                    standInfo.students.forEach((studentInfo) => {
                        if (studentInfo.name.toLowerCase().includes(search) || studentInfo.firstname.toLowerCase().includes(search) || studentInfo.id === search) {
                            console.log(studentInfo);

                            student.push(studentInfo);
                        }
                    });
                });
            });

            if (!student) return this.setState({ isLoading: false });

            this.setState({ student, isLoading: false });

        }


        return <>

            {this.state.isLoading ? <Loader /> : null}

            <div className="container mt-5">

                <div className="race_info mb-3">
                    <label className>Nom de l'élève, prénom, groupe ou N°</label>
                    <input type="text" className="form-control" ref={this.searchRef} autoFocus onKeyUp={(e) => { if (e.key === "Enter") searchPlayer() }} />

                    <button className="btn btn-primary mt-3" onClick={searchPlayer}>Rechercher</button>
                </div>

                {this.state.student.map((student) => {
                    return <div className="race_info mb-3">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td>NOM Prénom (#)</td>
                                        <td>Stand</td>
                                        <td>Nombre de tours</td>
                                        <td>Promesse totale</td>
                                        <td>À payer</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-bold">{student.name} {student.firstname} ({student.id})</td>
                                        <td className="fw-bold">N°{student.stand}</td>
                                        <td className="fw-bold">{student.tours} tour(s) - {Math.round(student.tours * 671 / 1000)} km<br /><small>Vitesse moyenne : {Math.round(((671 / 1000) * student.tours / (45 / 60)) * 100) / 100} km/h</small></td>
                                        <td className="fw-bold">{Math.round(student.promise * student.tours * 100) / 100}€</td>
                                        <td className="fw-bold">{Math.round((student.promise) * student.tours * 100) / 100 - student.payed}€</td>
                                    </tr>
                                </tbody>
                            </table>


                            {student.mdl === 1 ? <div className="alert alert-danger">Cet élève est parrainé par la MDL. Le paiement sera automatique.</div> : <>
                                L'élève a payé : <br />
                                <input type="number" className="form-control" ref={this.payedRef} defaultValue={student.payed} />
                                <button className="btn btn-primary mt-3" onClick={() => { editPlayerPayed(student.id, this.payedRef.current.value).then(() => { toast.success("Paiement enregistré !"); searchPlayer(); }).catch((error) => { toast.error("Erreur lors de l'enregistrement du paiement !"); }) }}>Enregistrer le paiement</button>
                            </>}
                        </div>
                    </div>
                })}

            </div>

        </>
    }
}

export default Payments;
