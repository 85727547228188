
import { Component, createRef } from "react";

// react-hot-toast
import toast from 'react-hot-toast';

import { userinfo } from "../components/api";
import { Link, NavLink } from "react-router-dom";

class Documents extends Component {

    constructor(props) {

        super(props);

        this.state = {
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            sound: "",
            groups: [],
            students: [],
            savedStudents: [],
            reports: [],
            rank: 'referent',
            tab: 'general',
            adminGroups: [],
            timeSlot: 0,
            end: 0
        }

        this.audioRef = createRef();
        this.fstRef = createRef();
        this.alertRef = createRef();
        this.soundRef = createRef();
        this.soundAnnounceRef = createRef();
        this.searchRef = createRef();

        this.reportStandRef = createRef();
        this.reportIncidentRef = createRef();

        this.standSectionRef = createRef();


    }
    componentDidMount() {

        window.scrollTo(0, 0);
        userinfo().then((response) => {

            if (response.rank !== "sysadmin") {
                toast.error("Vous n'avez pas les droits pour accéder à cette section");
                return window.location.href = "./hub";
            }

            this.setState({ rank: response.rank });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location.href = "./login";
        });

    }
    render() {

        return <>
            

            <div className="container mt-5">
                <div className="row">

                    <div className="col-xs-12">

                        <Link to={"/files/planning_du_jour.pdf"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>planning du jour</u></h3>
                                    <span className="file-subtitle">Emploi du temps détaillé de la journée de la course</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">PDF - 353 Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>
                    <div className="col-xs-12">

                        <Link to={"/files/parcours.png"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>plan de la course</u></h3>
                                    <span className="file-subtitle">Plan aérien du dispositif installé au Stade Robert Lemoine</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">PNG - 998 Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>
                    <div className="col-xs-12">

                        <Link to={"/files/Course 1.csv"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>tableur créneau 1</u></h3>
                                    <span className="file-subtitle">Tableur des élèves présents sur le créneau 1 de la course de 9h à 12h</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">CSV - 2.89 Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>
                    <div className="col-xs-12">

                        <Link to={"/files/Course 2.csv"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>tableur créneau 2</u></h3>
                                    <span className="file-subtitle">Tableur des élèves présents sur le créneau 2 de la course de 10h à 13h</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">CSV - 2.87 Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>

                    <div className="col-xs-12">

                        <Link to={"/files/Course 3.csv"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>tableur créneau 3</u></h3>
                                    <span className="file-subtitle">Tableur des élèves présents sur le créneau 3 de la course de 13h à 16h</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">CSV - 4.39 Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>

                    <div className="col-xs-12">

                        <Link to={"/files/Course 4.csv"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>tableur créneau 4</u></h3>
                                    <span className="file-subtitle">Tableur des élèves présents sur le créneau 4 de la course de 14h30 à 17h30</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">CSV - 4.79 Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>
                    <div className="col-xs-12">

                        <NavLink to={"/settings"}>
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Voir le document <u>identifiants plateforme ssrm 2024</u></h3>
                                    <span className="file-subtitle">Ensemble des identifiants pour se connecter à la plateforme de gestion de la course</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">HTML - / Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                    </div>

                    <div className="col-xs-12">

                        <Link to={"/files/Décharges.pdf"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>décharges des volontaires</u></h3>
                                    <span className="file-subtitle">Clause de non responsabilité signées de tous les bénévoles de l'événement</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">PDF - 7.19 Mo</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>
                    <div className="col-xs-12">

                        <Link to={"/files/Convention_PC.pdf"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>convention avec la protection civile</u></h3>
                                    <span className="file-subtitle">Contrat de partenariat avec la protection civile pour la sécurité de l'événement</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">PDF - 329 Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>
                    <div className="col-xs-12">

                        <Link to={"/files/badges.pdf"} target="_blank" rel="noreferrer">
                            <div className="card card-file mb-4">

                                <div className="card-body p-4">

                                    <h3 className="file-title">Télécharger le document <u>badges de la course</u></h3>
                                    <span className="file-subtitle">Ensemble des badges pour les bénévoles et personnels accrédités</span>

                                    <div className="file-footer">
                                        <span className="file-caracteristics">PDF - 139 Ko</span>
                                        <i className="fas fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    </div>


                </div>
            </div>

        </>
    }

}

export default Documents;