import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

// Composants
import { getCurrentStatus, userinfo } from "./components/api";
import { Navigation } from "./components/Navigation";
import config from "./components/config.json";
import toast from "react-hot-toast";

// Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Hub from "./pages/Hub";
import Documents from "./pages/Documents";
import Settings from "./pages/Settings";
import Stocks from "./pages/Stocks";
import Loader from "./components/Loader";
import PPMS from "./components/PPMS";
import Indisponible from "./pages/Indisponible";
import Payments from "./pages/Payments";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reports: [],
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            isLoading: true,
            PPMS: false,
            rank: "referent",
            disabled: true
        }

        this.audioRef = React.createRef();
        this.alertRef = React.createRef();
        this.soundAnnounceRef = React.createRef();
        this.fstRef = React.createRef();

    }

    componentDidMount() {

        getCurrentStatus().then((response) => {
            this.setState({ raceStatus: response.status, timeSlot: response.timeslot, end: response.end, reports: response.reports, published_scoreboard: response.published_scoreboard });
        }).catch((error) => {
            if (!localStorage.getItem("token")) return;
            toast.error("Erreur lors de la connexion !");
        });


        if (localStorage.getItem("token")) {
            userinfo().then((response) => {
                /*if (new Date().getTime() < new Date("2024-04-30 8:15:00").getTime() && (new Date().getTime() < new Date("2024-04-26 17:15:00").getTime() || new Date().getTime() > new Date("2024-04-26 18:15:00").getTime())
                ) {
                    if (response.rank !== "sysadmin") {
                        this.setState({ disabled: true });
                    }
                }*/
                this.setState({ rank: response.rank });

            }).catch((error) => {
                this.setState({ disabled: true });
            });
        }

        this.socket = new WebSocket(config.api.PRODUCTION_GATEWAY_URL);
        this.socket.onopen = () => {
            if (this.socket.readyState === WebSocket.OPEN) {
                this.socket.send(JSON.stringify({
                    "command": "login",
                    "token": "JAjBLSG5tGAZhJ8mbTrDsSAb"
                }));
                this.setState({ isLoading: false });
            }

        }


        this.socket.onclose = () => {

            if (!this.socket.readyState) {

                if (this.socket.readyState === WebSocket.CLOSED)
                    toast.error("La connexion avec le serveur a été fermée.");
                else if (this.socket.readyState === WebSocket.CLOSING)
                    toast.error("La connexion avec le serveur est en cours de fermeture.");
                else if (this.socket.readyState === WebSocket.CONNECTING)
                    toast.loading("La connexion avec le serveur est en cours d'établissement.");
                else
                    toast.error("La connexion avec le serveur a été perdue.");

            }

        }


        this.socket.onmessage = (message) => {

            let messageinfos = JSON.parse(message.data);

            if (messageinfos.event === "HEARTBEAT") {
                this.socket.send(JSON.stringify({
                    "command": "heartbeat"
                }));
            }

            if (messageinfos.event === "RACE_STATUS_EDITED") {
                this.audioRef.current.play();
                this.setState({
                    raceStatus: messageinfos.status,
                });

                if (messageinfos.status === 1) toast("La course a été lancée.", { icon: '🏁', });
                if (messageinfos.status === 2) toast("La course a été arrêtée.", { icon: '🏁', });


            }
            // REPORT_RECEIVED

            if (this.state.rank !== "referent" && messageinfos.event === "REPORT_RECEIVED") {

                while (!this.alertRef.current) setTimeout(() => { }, 750);

                this.alertRef.current.play();
                ; toast('Un incident a été signalé', {
                    icon: '🚨',
                });

                this.setState({ reports: [...this.state.reports, { timeslot: this.state.timeslot, content: messageinfos.report, stand: messageinfos.stand }] });

            }

            // PLAY_SOUND

            if (messageinfos.event === "PLAY_SOUND") {
                if (messageinfos.sound.includes("PPMS")) {
                    this.setState({ PPMS: true });
                    setTimeout(() => {
                        this.setState({
                            sound: messageinfos.sound
                        }, () => {
                            this.soundAnnounceRef.current.play();
                        });
                    }, 5000);
                } else {
                    this.setState({
                        sound: messageinfos.sound
                    }, () => {
                        this.soundAnnounceRef.current.play();
                    });
                }

            }

            if (messageinfos.event === "TIMESLOT_CHANGED") {
                this.componentDidMount();
            }

        }


    }

    render() {

        setTimeout(() => {

            if (this.fstRef.current) this.fstRef.current.click()

        }, 1000);


        return (

            <Router>
                {
                    // Si on est le 30 avril ou plus (2024)

                    this.state.disabled ?
                        <div className="App">
                            {this.state.PPMS ? <PPMS /> : null}
                            <Routes>
                                <Route path={"/*"} element={<Indisponible />} />
                            </Routes>
                        </div>

                        :

                        <div className="App">

                            {this.state.isLoading ? <Loader /> : null}
                            {this.state.PPMS ? <PPMS /> : null}

                            <audio ref={this.audioRef} src={process.env.PUBLIC_URL + "/sounds/notification.wav"} style={{ display: 'none' }} />
                            <audio ref={this.alertRef} src={process.env.PUBLIC_URL + "/sounds/alert.wav"} style={{ display: 'none' }} />
                            <audio ref={this.soundAnnounceRef} src={process.env.PUBLIC_URL + "/sounds/" + this.state.sound} style={{ display: 'none' }} />
                            <button ref={this.fstRef} style={{ display: 'none' }}></button>

                            <Navigation raceStatus={this.state.raceStatus} raceStatusText={this.state.raceStatusText} name={localStorage.getItem("name")} />

                            <div className="container mt-5">
                                {this.state.reports && this.state.reports.length > 0 ?
                                    <>
                                        <span className="title">
                                            <i className="fa-solid fa-triangle-exclamation me-2 text-secondary"></i> Alertes
                                        </span>

                                        {
                                            this.state.reports.map((report) => {
                                                return <div className="alert alert-danger" role="alert">
                                                    <i className="fa-solid fa-radiation me-2"></i> Vous avez été bippé : <b>STAND {report.stand}</b><br />

                                                    Motif: <b>{report.content}</b>
                                                </div>
                                            }
                                            )
                                        }

                                        <hr />
                                    </>
                                    : null}
                            </div>


                            <Routes>
                                <Route path={"/*"} element={<Hub />} />
                                <Route path={"/login"} element={<Login />} />
                                <Route path={"/referent"} element={<Home raceStatus={this.state.raceStatus ? this.state.raceStatus : 0} timeSlot={this.state.timeSlot} end={this.state.end} socket={this.socket} />} />
                                <Route path={"/dashboard"} element={<Dashboard raceStatus={this.state.raceStatus ? this.state.raceStatus : 0} timeSlot={this.state.timeSlot} end={this.state.end} />} />
                                <Route path={"/documents"} element={<Documents raceStatus={this.state.raceStatus ? this.state.raceStatus : 0} timeSlot={this.state.timeSlot} end={this.state.end} />} />
                                <Route path={"/settings"} element={<Settings raceStatus={this.state.raceStatus ? this.state.raceStatus : 0} timeSlot={this.state.timeSlot} end={this.state.end} />} />
                                <Route path={"/stocks"} element={<Stocks raceStatus={this.state.raceStatus ? this.state.raceStatus : 0} timeSlot={this.state.timeSlot} end={this.state.end} />} />
                                <Route path={"/payments"} element={<Payments raceStatus={this.state.raceStatus ? this.state.raceStatus : 0} timeSlot={this.state.timeSlot} end={this.state.end} />} />
                            </Routes>

                        </div>}
            </Router>
        );

    }
};

export default App;
