
import { Component, createRef } from "react";
import toast from 'react-hot-toast';

// Api

import { createSSRMAcount, editConfig, getCredentials, userinfo } from "../components/api";
import Loader from "../components/Loader";

//import { preRegister } from "../components/api";
class Settings extends Component {

    constructor(props) {

        super(props);

        this.state = {
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            sound: "",
            groups: [],
            students: [],
            savedStudents: [],
            reports: [],
            rank: 'referent',
            tab: 'general',
            adminGroups: [],
            end: 0,
            timeslot: 0,
            credentials: [],
            isLoading: true,
        }

        this.reportStandRef = createRef();
        this.reportIncidentRef = createRef();

        this.standSectionRef = createRef();
        this.creneauRef = createRef();
        this.statusRef = createRef();

        this.rankRef = createRef();
        this.identifiantRef = createRef();


    }

    componentDidMount() {

        window.scrollTo(0, 0);
        userinfo().then((response) => {
            if (response.rank !== "sysadmin") return window.location.href = "/";
            this.setState({ rank: response.rank })
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
            window.location.href = "./login";;
        });

        getCredentials().then((response) => {
            this.setState({ credentials: response.credentials });
            this.setState({ isLoading: false });
        }).catch((error) => {
            toast.error("Impossible de récupérer les identifiants.");
        });

    }
    render() {


        const handleEditConfig = (timeslot, published_scoreboard) => {

            this.setState({ isLoading: true });
            if (!published_scoreboard) published_scoreboard = false;
            if (!timeslot) timeslot = this.props.timeSlot;

            editConfig(timeslot, published_scoreboard).then((response) => {
                toast.success("Les créneaux ont été modifiés.");
                this.setState({ isLoading: false });
            }).catch((error) => {
                toast.error("Impossible de modifier les créneaux.");
                this.setState({ isLoading: false });
            });

        }

        const handleCreateAccount = () => {

            this.setState({ isLoading: true });

            createSSRMAcount(this.identifiantRef.current.value, this.rankRef.current.value).then((response) => {
                toast.success("Identifiant créé !");

                getCredentials().then((response) => {
                    this.setState({ credentials: response.credentials });
                    this.setState({ isLoading: false });
                }).catch((error) => {
                    toast.error("Impossible de récupérer les identifiants.");
                });

            }).catch((error) => {
                toast.error("Impossible de créer l'identifiant.");
                this.setState({ isLoading: false });
            });

        }



        return <>
            {this.state.isLoading ? <Loader /> : null}

            <div className="container mt-5">

                <div className="race_info mb-3">
                    Modifiez le créneau actuel renseigné pour mettre à jour les créneaux pour la nouvelle phase de la course. Attention, cette action est irréversible et les informations de la course précédente seront supprimées (sauf les résultats des élèves). Les résultats du classement ne seront plus affichés au public.

                    <label className="fr label mt-3">Date et heure du créneau</label>
                    <input className="fr input" type="datetime-local" ref={this.creneauRef} autoComplete="off" />


                    <button className="btn-main mt-3" onClick={() => {
                        handleEditConfig(
                            new Date(this.creneauRef.current.value).getTime() / 1000,
                            false
                        )
                    }}>Enregistrer</button>

                </div>



                <div className="race_info mb-4">


                    <h3 className="tile-title">Identifiants du SSRM</h3>
                    <span className="tile-subtitle">Ces identifiants ne peuvent être modifiés qu'en base de données.</span>

                    <div className="table-responsive my-4">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Identifiant</th>
                                    <th>Rôle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.credentials.map((credential) => {
                                    return <tr>
                                        <td>{credential.name}</td>
                                        <td>{credential.code}</td>
                                        <td>{credential.rank === "sysadmin" ? <badge className="badge-admin">Administrateur</badge> : credential.rank === "arbitre" ? <badge className="badge-arbitre">Arbitre</badge> : credential.rank === "surveillant" ? <badge className="badge-surveillant">Surveillant</badge> : <badge className="badge-referent">Référent</badge>}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>

                    </div>

                    <button className="btn-main" data-bs-toggle="modal" data-bs-target="#createAccount">Créer un identifiant</button>

                </div>
            </div>


            <div className="modal fade" id="createAccount" tabindex="-1" aria-labelledby="createAccountLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="createAccountLabel">Créer un identifiant</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label className="fr label">Identifiant</label>
                            <span className="fr sublabel">Format attendu : NOM</span>
                            <input className="fr input" type="text" ref={this.identifiantRef} />

                            <label className="fr label">Rang</label>
                            <select className="fr input" ref={this.rankRef}>
                                <option disabled selected>Choisir un rang</option>
                                <option disabled---------->---</option>
                                <option value="sysadmin">Administrateur système</option>
                                <option value="arbitre">Arbitre</option>
                                <option value="referent">Référent</option>
                                <option value="surveillant">Surveillant</option>
                            </select>

                            <button className="btn-main mt-3 m-3" onClick={() => {
                                handleCreateAccount()
                            }}>Créer</button>
                            <button className="btn-outline-main mt-3" data-bs-dismiss="modal">Annuler</button>

                        </div>
                    </div>
                </div>
            </div>

        </>
    }
}

export default Settings;
