
import { Component, createRef } from "react";

// react-hot-toast
import toast from 'react-hot-toast';

// Api

import { deliverStock, getAllGroups, getStocks, playSound, reportIncident, updateStock, userinfo } from "../components/api";
import Loader from "../components/Loader";

//import { preRegister } from "../components/api";
class Stocks extends Component {

    constructor(props) {

        super(props);

        this.state = {
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            sound: "",
            groups: [],
            students: [],
            savedStudents: [],
            reports: [],
            rank: 'referent',
            tab: 'stocks',

            end: 0,
            timeslot: 0,
            stockedItems: [],
            adminGroups: [],
            isLoading: true

        }

        this.soundRef = createRef();

        this.reportStandRef = createRef();
        this.reportIncidentRef = createRef();

        this.standSectionRef = createRef();
        this.creneauRef = createRef();
        this.statusRef = createRef();


    }

    componentDidMount() {

        window.scrollTo(0, 0);
        userinfo().then((response) => {
            if (response.rank !== "sysadmin" && response.rank !== "arbitre" && response.rank !== "surveillant") return window.location.href = "/";
            this.setState({ rank: response.rank })
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
            window.location.href = "./login";;
        });
        // Stocks

        getStocks().then((response) => {
            let res = response.items;
            if (!Array.isArray(res)) res = [res];
            this.setState({ stockedItems: res });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
        });


        getAllGroups().then((response) => {
            let res = response.groups;
            if (!Array.isArray(res)) res = [res];

            // Trier res.stands.students par name ASC
            res.forEach((timeslotInfo) => {
                timeslotInfo.stands.forEach((standInfo) => {
                    standInfo.students.sort((a, b) => a.name.localeCompare(b.name));
                });
            });

            this.setState({ adminGroups: res, isLoading: false });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
        });



    }
    render() {

        const handleReportIncident = () => {
            this.setState({ isLoading: true });
            reportIncident(this.reportIncidentRef.current.value, this.reportStandRef.current.value, this.props.timeSlot).then((response) => {
                toast.success("L'incident a été signalé !");
                this.setState({ isLoading: false });
            }).catch((error) => {
                toast.error("Impossible de signaler l'incident.");
                this.setState({ isLoading: false });
            });
        }

        const handlePlaySound = (sound) => {
            this.setState({ isLoading: true });
            playSound(sound).then((response) => {
                toast.success("L'annonce a été lancée !");
                this.setState({ isLoading: false });
            }).catch((error) => {
                toast.error("Impossible de lancer l'annonce.");
                this.setState({ isLoading: false });
            });

        }

        const handleEditStock = (itemId, quantity) => {
            this.setState({ isLoading: true });
            updateStock(itemId, quantity).then((response) => {

                toast.success("Le stock a été modifié.");
                // Modifier le state
                let items = this.state.stockedItems;
                let index = items.findIndex((item) => item.item_id === itemId);
                items[index].amount = quantity;
                this.setState({ stockedItems: items });
                this.setState({ isLoading: false });


            }).catch((error) => {
                toast.error("Impossible de modifier le stock.");
                this.setState({ isLoading: false });
            });
        }

        const deliverStocks = (stand, timeslot, amount) => {
            this.setState({ isLoading: true });
            deliverStock(stand, timeslot, amount).then((response) => {
                toast.success("Le stock a été modifié.");

                this.componentDidMount();
                this.setState({ isLoading: false });

            }).catch((error) => {
                toast.error("Impossible de modifier le stock.");
                this.setState({ isLoading: false });
            });

        }




        return <>
            {this.state.isLoading ? <Loader /> : null}
            <div className="container mt-5">
                <div className="row">

                    <div className="col-xs-12 col-md-6 col-xl-4 col-xxl-3 mb-3">

                        <label>Actions</label>

                        <div className="d-grid gap-2">

                            <button className="btn-main w-100 justify-content-center" data-bs-toggle="modal" data-bs-target="#reportIncidentModal">Signaler un incident <i className="fa-solid fa-flag ms-3 fa-xs"></i></button>
                            <button className="btn-main w-100 justify-content-center" data-bs-toggle="modal" data-bs-target="#playSoundModal">Annonce sonore <i className="fa-solid fa-bullhorn ms-3 fa-xs"></i></button>


                        </div>

                    </div>

                    <div className="col-xs-12 col-md-6 col-xl-8 col-xxl-9">

                        <div className="alert alert-primary">
                            Vos distributions s'affichent ici. S'il n'y en a aucune, c'est que vous n'avez rien à livrer pour le moment.<br />
                            Merci de vérifier les stocks restants et de les ajuster en conséquence.
                        </div>


                        {this.state.adminGroups && this.state.adminGroups.length > 0 ?
                            this.state.adminGroups.map((timeslotInfo, index) => {
                                if (timeslotInfo.timeSlot !== this.props.timeSlot) return <></>;

                                return timeslotInfo.stands.map((standInfo) => {
                                    if (standInfo.distributed_stocks === 1) return <></>;

                                    return <div className="card card-todo my-2" style={{ borderBottom: "3px solid var(--primary) !important" }} key={standInfo.stand}>
                                        <div className="card-body">
                                            <h3 className="card-title">Stand N°{standInfo.stand} - {standInfo.groupLink}</h3>

                                            <div className="card-subtitle mb-3">
                                                <ul>
                                                    <li>{standInfo.students.length + 1} bouteilles d'eau</li>
                                                    <li>{standInfo.students.length + 1} pommes</li>
                                                    <li>{standInfo.students.length + 1} barres de céréales</li>
                                                </ul>
                                            </div>

                                            <div className="card-bottom">


                                                Créneau de {new Date(timeslotInfo.timeSlot * 1000).toLocaleTimeString()} à {new Date(timeslotInfo.timeSlot * 1000 + 60 * 60 * 1000).toLocaleTimeString()}

                                                <button className="btn-main ms-3" onClick={() => {

                                                    deliverStocks(standInfo.stand, timeslotInfo.timeSlot, standInfo.students.length + 1);
                                                }}>Distribué <i className="fa-solid fa-check ms-3 fa-xs text-white" style={{ fontSize: "1rem" }}></i></button>

                                            </div>

                                        </div>
                                    </div>


                                })

                            }) : <></>

                        }

                        <div className="race_info mb-3">
                            <span className="title"><i className="fa-solid fa-cart-flatbed me-2 text-secondary"></i> Inventaire</span>

                            <div className="table-responsive">
                                <table className="table table-stripped table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-muted">Produit</th>
                                            <th scope="col" className="text-muted">Restant total</th>
                                            <th scope="col" className="text-muted"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.stockedItems.map((item) => {
                                                return <tr>
                                                    <td>{item.title ? String(item.title).toUpperCase() : "Item non désigné"}</td>
                                                    <td>

                                                        x<input className="form-integer" type="number"
                                                            onBlur={(e) => {
                                                                // S'il n'y a pas de changement, on return
                                                                if (e.target.value == item.amount) return;

                                                                handleEditStock(item.item_id, e.target.value)

                                                            }
                                                            }

                                                            defaultValue={item.amount} style={{ width: "3rem" }} />

                                                    </td>
                                                    <td>
                                                        <i className="fa-solid fa-minus-circle me-2 text-primary hoverCursorClick" onClick={() => handleEditStock(item.item_id, item.amount - 1)}></i>
                                                        <i className="fa-solid fa-plus-circle me-2 text-primary hoverCursorClick" onClick={() => handleEditStock(item.item_id, item.amount + 1)}></i>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >






            <div className="modal modal-lg fade" id="playSoundModal" tabindex="-1" aria-labelledby="playSoundModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="playSoundModalLabel">Annonce sonore</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label className="fr label">Annonce à effectuer</label>
                            <label className="fr sublabel">Le message sera diffusé à toutes les personnes en ligne sur le site.</label>

                            <select className="fr input" ref={this.soundRef}>
                                <option value="RAPPEL PELOUSE.mp3">Ne pas marcher sur la pelouse</option>
                                <option value="Suivre parcours.mp3">Merci de suivre le parcours</option>
                                <option value="1 - Bienvenue.mp3">1 - Bienvenue.mp3</option>
                                <option value="2 - Infos course.mp3">2 - Infos course.mp3</option>
                                <option value="3 - FIN COURSE.mp3">3 - FIN COURSE.mp3</option>
                                <option value="Collation S123.mp3">Distribution de collations stands 1 à 3</option>
                                <option value="Collation 456.mp3">Distribution de collations stands 4 à 6</option>
                                <option value="Parking.mp3">Ne pas s'approcher des parkings</option>
                                <option value="Course météo.mp3">Suspension course pour cause météo</option>
                            </select>


                            <button type="button" className="btn-main mb-3 me-3" data-bs-dismiss="modal"
                                onClick={() => handlePlaySound(this.soundRef.current.value)}>Lancer l'annonce</button>
                            <button type="button" className="btn-outline-main" data-bs-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal modal-lg fade" id="reportIncidentModal" tabindex="-1" aria-labelledby="reportIncidentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="reportIncidentModalLabel">Signaler un incident</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <label className="fr label">Votre N° de stand</label>
                            <label className="fr sublabel">Format attendu : 1 (si vous n'êtes sur aucun stand, entrez 7)</label>
                            <input className="fr input" type="text" ref={this.reportIncidentRef} />


                            <label className="fr label">Type d'incident</label>
                            <input className="fr input" type="text" ref={this.reportStandRef} autoComplete="off" list="datalistOptions" />
                            <datalist id="datalistOptions">
                                <option value="Malaise coureur" />
                                <option value="Besoin de collation (eau, pomme, ...)" />
                                <option value="Incident technique (stand, ...)" />
                                <option value="Autre" />
                            </datalist>


                            <button type="button" className="btn-main mb-3 me-3" data-bs-dismiss="modal"
                                onClick={() => handleReportIncident()}>Signaler</button>
                            <button type="button" className="btn-outline-main" data-bs-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    }
}

export default Stocks;
