
import { Component, createRef } from "react";

// react-hot-toast
import toast from 'react-hot-toast';

// Api

import { editPlayerPayed, getAllGroups, getPlayerLeaderboard, playSound, reportIncident, startRace, stopRace, userinfo } from "../components/api";
import Loader from "../components/Loader";

//import { preRegister } from "../components/api";
class Dashboard extends Component {

    constructor(props) {

        super(props);

        this.state = {
            raceStatus: 0,
            raceStatusText: "En attente de la course",
            sound: "",
            groups: [],
            students: [],
            savedStudents: [],
            rank: 'referent',
            tab: 'stands',
            adminGroups: [],
            end: 0,
            timeslot: 0,
            isLoading: true,
        }

        this.soundRef = createRef();
        this.soundPPMSRef = createRef();

        this.reportStandRef = createRef();
        this.reportIncidentRef = createRef();

        this.standSectionRef = createRef();
        this.creneauRef = createRef();
        this.statusRef = createRef();


    }

    componentDidMount() {

        window.scrollTo(0, 0);
        userinfo().then((response) => {
            if (response.rank !== "sysadmin" && response.rank !== "arbitre" && response.rank !== "surveillant") return window.location.href = "/";
            this.setState({ rank: response.rank })
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
            window.location.href = "./login";;
        });

        getAllGroups().then((response) => {
            let res = response.groups;
            if (!Array.isArray(res)) res = [res];

            // Trier res.stands.students par name ASC
            res.forEach((timeslotInfo) => {
                timeslotInfo.stands.forEach((standInfo) => {
                    standInfo.students.sort((a, b) => a.name.localeCompare(b.name));
                });
            });

            this.setState({ adminGroups: res, isLoading: false });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
        });

        getPlayerLeaderboard().then((response) => {
            this.setState({ bestRacers: response.bestRacers, bestDonators: response.bestDonators });
        }).catch((error) => {
            toast.error("Erreur lors de la connexion !");
            window.location.href = "./login";;
        });



    }
    render() {

        const handleStartRace = () => {
            this.setState({ isLoading: true })
            startRace().then((response) => {
                toast.success("La course a été lancée !");
                this.setState({ isLoading: false })
            }).catch((error) => {
                toast.error("Impossible de lancer la course.");
                this.setState({ isLoading: false })
            });
        }

        const handleStopRace = () => {

            this.setState({ isLoading: true })

            stopRace().then((response) => {
                toast.success("La course a été arrêtée !");
                this.setState({ isLoading: false })
            }).catch((error) => {
                toast.error("Impossible d'arrêter la course.");
                this.setState({ isLoading: false })
            });

        }

        const handleReportIncident = () => {
            this.setState({ isLoading: true })
            reportIncident(this.reportIncidentRef.current.value, this.reportStandRef.current.value, this.props.timeSlot).then((response) => {
                toast.success("L'incident a été signalé !");
                this.setState({ isLoading: false })
            }).catch((error) => {
                toast.error("Impossible de signaler l'incident.");
                this.setState({ isLoading: false })
            });
        }

        const handlePlaySound = (sound) => {
            this.setState({ isLoading: true })
            playSound(sound).then((response) => {
                toast.success("L'annonce a été lancée !");
                this.setState({ isLoading: false })
            }).catch((error) => {
                toast.error("Impossible de lancer l'annonce.");
                this.setState({ isLoading: false })
            });

        }

        const handlePrintStands = () => {

            this.setState({ isLoading: true })

            let printWindow = window.open('', '', 'height=600,width=800');
            printWindow.document.write('<html><head><title>Stands</title>');
            printWindow.document.write('</head><body >');
            printWindow.document.write('<h1>Stands</h1>');
            printWindow.document.write('</td></tr>');
            this.state.adminGroups.forEach((timeslotInfo, index) => {
                timeslotInfo.stands.forEach((standInfo) => {
                    // Pour chaque stand, on fait un tableau PAR GROUP
                    printWindow.document.write('<h2>Course N°' + (index + 1) + ' le ' + new Date(timeslotInfo.timeSlot * 1000).toLocaleDateString() + ' à ' + new Date(timeslotInfo.timeSlot * 1000).toLocaleTimeString() + '</h2>');
                    printWindow.document.write('<table border="1">');
                    printWindow.document.write('<tr><th>Stand</th><th>Référent</th><th>Groupe</th><th>Elèves</th><th>Promesses</th><th>Tours</th></tr>');
                    printWindow.document.write('<tr><td>' + standInfo.stand + '</td><td>' + standInfo.referent.split(",").map((referent) => referent.trim()).join("<br/>") + '</td><td>' + standInfo.groupLink + '</td><td>');
                    standInfo.students.forEach((student) => {
                        printWindow.document.write((student.name.length > 10 ? student.name.substring(0, 10) + '...' : student.name) + " " + (student.firstname.length > 10 ? student.firstname.substring(0, 10) + '...' : student.firstname) + " (" + student.id + ")<br />");
                    }
                    );
                    printWindow.document.write('</td><td>');
                    standInfo.students.forEach((student) => {
                        printWindow.document.write(student.promise + "€<br />");
                    }
                    );
                    printWindow.document.write('</td><td>');
                    standInfo.students.forEach((student) => {
                        printWindow.document.write(student.tours + "<br />");
                    }
                    );
                    printWindow.document.write('</td></tr>');
                    printWindow.document.write('</table>');

                    printWindow.document.write('<div style="page-break-after: always;"></div>');

                });
            });
            printWindow.document.close();
            printWindow.print();

            this.setState({ isLoading: false })

        }
        return <>

            {this.state.isLoading ? <Loader /> : null}

            <div className="container mt-5">
                <div className="row">

                    <div className="col-xs-12 col-md-6 col-xl-4 col-xxl-3 mb-3">

                        <label>Actions</label>

                        <div className="d-grid gap-2">

                            {this.state.rank === "sysadmin" || this.state.rank === "arbitre" ? <>{this.props.raceStatus !== 1 ?
                                <button className="btn-main w-100 btn-success justify-content-center" type="button" onClick={() => handleStartRace()}>Lancer la course</button> :
                                <button className="btn-main w-100 btn-danger justify-content-center" type="button" onClick={() => handleStopRace()}>Arrêter la course</button>
                            } </> : null}

                            <button className="btn-main w-100 justify-content-center" data-bs-toggle="modal" data-bs-target="#reportIncidentModal">Signaler un incident <i className="fa-solid fa-flag ms-3 fa-xs"></i></button>
                            <button className="btn-main w-100 justify-content-center" data-bs-toggle="modal" data-bs-target="#playSoundModal">Annonce sonore <i className="fa-solid fa-bullhorn ms-3 fa-xs"></i></button>

                            {this.state.rank === "sysadmin" || this.state.rank === "arbitre" ? <button className="btn-main w-100 justify-content-center" type="button" data-bs-toggle="modal" data-bs-target="#PPMSModal" >Activer un PPMS <i className="fa-solid fa-exclamation-triangle ms-2 text-danger"></i></button> : null}

                            {this.state.rank === "sysadmin" ? <>
                                <button className="btn-outline-main w-100 justify-content-center" type="button" onClick={() => handlePrintStands()}>Imprimer les stands</button>
                            </> : null}

                        </div>

                    </div>

                    <div className="col-xs-12 col-md-6 col-xl-8 col-xxl-9">

                        <h3 className="mb-3">Panneau de gestion</h3>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <button className={this.state.tab === 'stands' ? "btn btn-link nav-link active" : "btn btn-link nav-link"} onClick={() => this.setState({ tab: 'stands' })}>Stands</button>
                            </li>

                            <li className="nav-item">
                                <button className={this.state.tab === 'scoreboard' ? "btn btn-link nav-link active" : "btn btn-link nav-link"} onClick={() => this.setState({ tab: 'scoreboard' })}>Classement</button>
                            </li>
                        </ul>


                        {this.state.tab === 'scoreboard' ? <div className="race_info mb-3">
                            <span className="title"><i className="fa-solid fa-trophy me-2 text-secondary"></i> Classement</span>

                            <div className="alert alert-info border-primary mb-4">
                                <small className="text-muted">Argent promis: {
                                    // Somme des promises * tours
                                    Math.round(this.state.adminGroups.reduce((acc, timeslotInfo) => {
                                        return acc + timeslotInfo.stands.reduce((acc, standInfo) => {
                                            return acc + standInfo.students.reduce((acc, student) => {
                                                return acc + student.promise * student.tours;
                                            }, 0);
                                        }, 0);
                                    }, 0) * 100) / 100
                                }€</small><br />
                                <small className="text-muted">Argent récolté: {
                                    // Somme des payed
                                    Math.round(this.state.adminGroups.reduce((acc, timeslotInfo) => {
                                        return acc + timeslotInfo.stands.reduce((acc, standInfo) => {
                                            return acc + standInfo.students.reduce((acc, student) => {
                                                return acc + student.payed;
                                            }, 0);
                                        }, 0);
                                    }, 0) * 100) / 100
                                }€</small><br />
                                <small className="text-muted">Argent à payer par la MDL : {
                                    // Somme des promises * tours quand mdl=1

                                    Math.round(this.state.adminGroups.reduce((acc, timeslotInfo) => {
                                        return acc + timeslotInfo.stands.reduce((acc, standInfo) => {
                                            return acc + standInfo.students.reduce((acc, student) => {
                                                return acc + (student.mdl === 1 ? student.promise * student.tours : 0);
                                            }, 0);
                                        }, 0);
                                    }, 0) * 100) / 100

                                }€</small><br />

                                <small className="text-muted">Nombre de km: {
                                    // Somme des tours
                                    Math.round(this.state.adminGroups.reduce((acc, timeslotInfo) => {
                                        return acc + timeslotInfo.stands.reduce((acc, standInfo) => {
                                            return acc + standInfo.students.reduce((acc, student) => {
                                                return acc + student.tours;
                                            }, 0);
                                        }, 0);
                                    }, 0) * 671 / 1000 * 1000) / 1000
                                }km</small><br />





                            </div>
                            <div className="alert alert-info border-primary mb-4">
                                <label className="title-section">Meilleurs coureurs</label>
                                <h3 className="first-best">{this.state.bestRacers ? this.state.bestRacers[0].name + " " + this.state.bestRacers[0].firstname + " (" + this.state.bestRacers[0].tours + " tours)" : "Aucun"}</h3>
                                <small className="text-muted">Suivi par : {this.state.bestRacers ? this.state.bestRacers.slice(1).map((student) => { return student.name + " " + student.firstname + " (" + student.tours + " tours)"; }).join(" et ") : "Aucun"}</small>
                            </div>
                            <div className="alert alert-info border-primary mb-4">
                                <label className="title-section">Meilleurs donateurs</label>
                                <h3 className="first-best">{this.state.bestDonators ? this.state.bestDonators[0].name + " " + this.state.bestDonators[0].firstname + " (" + this.state.bestDonators[0].promise * this.state.bestDonators[0].tours + "€)" : "Aucun"}</h3>
                                <small className="text-muted">Suivi par : {this.state.bestDonators ? this.state.bestDonators.slice(1).map((student) => { return student.name + " " + student.firstname + " (" + student.promise * student.tours + "€)"; }).join(" et ") : "Aucun"}</small>
                            </div>


                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">Groupe</th>
                                        <th scope="col">Ratio Tr/élève</th>
                                        <th scope="col">Promesse</th>
                                        <th scope="col">Dons payés</th>
                                        <th scope="col">Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.adminGroups.map((timeslotInfo, index) => {


                                            return timeslotInfo.stands.map((standInfo) => {


                                                let studentsAmount = 0;
                                                standInfo.students.forEach((student) => {
                                                    if (student.disp !== 1 && student.tours > 0) studentsAmount++;
                                                });

                                                return <tr>
                                                    <th scope="row">{standInfo.groupLink}</th>
                                                    <td>
                                                        {
                                                            Math.round((standInfo.students.reduce((acc, student) => {
                                                                return acc + student.tours;
                                                            }, 0) / studentsAmount) * 100) / 100
                                                        }
                                                    </td>
                                                    <td>
                                                        {Math.round(standInfo.students.reduce((acc, student) => {
                                                            return acc + student.promise * student.tours;
                                                        }, 0) * 100) / 100}€
                                                    </td>
                                                    <td>
                                                        {Math.round(standInfo.students.reduce((acc, student) => {
                                                            return acc + student.payed;
                                                        }, 0) * 100) / 100}€
                                                    </td>



                                                    <td title="√(2*ratio + ln(dons))" style={{ cursor: "help" }}>
                                                        {
                                                            Math.round(2 * Math.round(Math.sqrt((standInfo.students.reduce((acc, student) => {
                                                                return acc + student.tours;
                                                            }, 0) / studentsAmount) + (standInfo.students.reduce((acc, student) => {
                                                                return acc + student.promise * student.tours;
                                                            }, 0))) * 1000) / 1000) * 100 / 100
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                        })
                                    }
                                </tbody>
                            </table>

                        </div> : null}

                        {this.state.tab === 'stands' ? <>
                            <div ref={this.standSectionRef}>

                                <span className="mb-3">Au total, <b>{this.state.adminGroups.reduce((acc, timeslotInfo) => { return acc + timeslotInfo.stands.reduce((acc, standInfo) => { return acc + standInfo.students.length; }, 0) }, 0)} élèves</b> sont inscrits à la course.</span>

                                {
                                    this.state.adminGroups.map((timeslotInfo, index) => {
                                        return <div className="race_info my-2">

                                            <span className="title"><i className={"fa-solid fa-" + (index + 1) + " me-2 text-secondary"}></i> Course N°{index + 1} ({

                                                timeslotInfo.stands.reduce((acc, standInfo) => {
                                                    return acc + standInfo.students.length;
                                                }, 0)


                                            } élèves)</span>

                                            {
                                                timeslotInfo.stands.map((standInfo) => {
                                                    return <><b>Stand N°{standInfo.stand}: {standInfo.groupLink} de {standInfo.referent} ({standInfo.students.length} élèves)
                                                        <i className="fa-solid fa-chevron-down ms-2 text-secondary hoverCursorClick"></i></b><br />
                                                        <small>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Coureur (#)</th>
                                                                        <th scope="col">Promesse<br />Tours</th>
                                                                        <th scope="col">À payer</th>
                                                                        <th scope="col">Payé</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        standInfo.students.map((student) => {
                                                                            return <tr id={student.id} key={student.id}>
                                                                                <td className={student.disp && student.disp === 1 ? "text-danger" : null
                                                                                }>{student.name} {student.firstname} ({student.id})</td>

                                                                                <td>
                                                                                    {student.disp && student.disp === 1 ? <b title="Comité d'organisation sur cette course" style={{ cursor: "help" }}><i className="fa-solid fa-exclamation-triangle me-2 text-danger"></i> C.O.</b> : student.promise + "€"}
                                                                                    <br />
                                                                                    {student.disp && student.disp === 1 ? <b title="Comité d'organisation sur cette course" style={{ cursor: "help" }}><i className="fa-solid fa-exclamation-triangle me-2 text-danger"></i> C.O.</b> : <>{student.tours} tour{student.tours > 1 ? "s" : ""}</>}
                                                                                </td>
                                                                                <td>{Math.round(student.promise * student.tours * 100) / 100}€</td>
                                                                                <td>
                                                                                    <input type="number" className="form-control" defaultValue={student.payed} onBlur={(e) => {
                                                                                        toast.promise(editPlayerPayed(student.id, e.target.value), {
                                                                                            loading: 'Enregistrement...',
                                                                                            success: 'Paiement enregistré',
                                                                                            error: 'Erreur lors de l\'enregistrement'
                                                                                        });
                                                                                    }} onKeyDown={(e) => { if (e.key === "Enter") e.target.blur() }} />
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </small>
                                                        <br /><br /></>
                                                })
                                            }

                                        </div>

                                    })


                                }
                            </div>

                        </> : null}




                    </div>

                </div>
            </div >



            <div className="modal fade" id="playSoundModal" tabindex="-1" aria-labelledby="playSoundModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="playSoundModalLabel">Annonce sonore</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label className="fr label">Annonce à effectuer</label>
                            <select className="fr input" ref={this.soundRef}>
                                <option value="RAPPEL PELOUSE.mp3">Ne pas marcher sur la pelouse</option>
                                <option value="Suivre parcours.mp3">Merci de suivre le parcours</option>
                                <option value="1 - Bienvenue.mp3">1 - Bienvenue.mp3</option>
                                <option value="2 - Infos course.mp3">2 - Infos course.mp3</option>
                                <option value="3 - FIN COURSE.mp3">3 - FIN COURSE.mp3</option>
                                <option value="Collation S123.mp3">Distribution de collations stands 1 à 3</option>
                                <option value="Collation 456.mp3">Distribution de collations stands 4 à 6</option>
                                <option value="Parking.mp3">Ne pas s'approcher des parkings</option>
                                <option value="Course météo.mp3">Suspension course pour cause météo</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-primary"
                                onClick={() => handlePlaySound(this.soundRef.current.value)}>Lancer l'annonce</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="PPMSModal" tabindex="-1" aria-labelledby="PPMSModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="PPMSModalLabel">Plan Particulier de Mise en Sûreté</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label className="fr label">PPMS à activer</label>
                            <select className="fr input" ref={this.soundPPMSRef}>
                                <option value="PPMS Test.mp3">PPMS Test</option>
                                <option value="PPMS Confinement.mp3">PPMS Confinement</option>
                                <option value="PPMS Evacuation.mp3">PPMS Evacuation</option>
                                <option value="PPMS Fin alerte.mp3">Fin alerte</option>
                                <option value="PPMS Incendie.mp3">PPMS Incendie</option>
                                <option value="PPMS Intrusion.mp3">PPMS Intrusion</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-primary"
                                onClick={() => {
                                    handlePlaySound(this.soundPPMSRef.current.value)

                                    this.reportIncidentRef.current.value = 7;
                                    this.reportStandRef.current.value = "Un plan particulier de mise en sûreté a été activé";
                                    handleReportIncident();
                                }}>Lancer l'annonce</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal modal-lg fade" id="reportIncidentModal" tabindex="-1" aria-labelledby="reportIncidentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="reportIncidentModalLabel">Signaler un incident</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <label className="fr label">Votre N° de stand</label>
                            <label className="fr sublabel">Format attendu : 1 (si vous n'êtes sur aucun stand, entrez 7)</label>
                            <input className="fr input" type="text" ref={this.reportIncidentRef} />


                            <label className="fr label">Type d'incident</label>
                            <input className="fr input" type="text" ref={this.reportStandRef} autoComplete="off" list="datalistOptions" />
                            <datalist id="datalistOptions">
                                <option value="Malaise coureur" />
                                <option value="Besoin de collation (eau, pomme, ...)" />
                                <option value="Incident technique (stand, ...)" />
                                <option value="Autre" />
                            </datalist>

                            <button type="button" className="btn-main mb-3 me-3" data-bs-dismiss="modal"
                                onClick={() => handleReportIncident()}>Signaler</button>
                            <button type="button" className="btn-outline-main" data-bs-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    }
}

export default Dashboard;
